/*
* notificationMessage scss
* */

//@import '../../styles/configuration';

notification-message {
  display: block;

  .es-panel {
	  border-bottom: 0px;
  }
}
