/*
 * pageHeader scss
 * */

page-header {
	display: block;
	.es-page-header {
		.es-page-header-title {
			// dropdowns in the page header are not yet supported in the SG
			.ert-page-action+.ert-page-action {
				margin: 0 1rem 0 0;
			}
			.ert-page-action {
				float: right;
				display: inline-block;
				padding: 0;
				.es-dropdown {
					padding: 0;
				}
			}
		}
	}
}
