/*
* studyMetricsDetailsPopup scss
* */
@import '../../../../styles/configuration';

study-metrics-details-popup {
  display: block;

  .ert-holter-card {
	  margin: $es-space-stack-small;
  }
}
