/*
* sideNavigation scss
* */

@import '../../styles/configuration';

@mixin sidebar-patch-height($sidebar-item-height-small, $sidebar-item-chevron-position-small) {
  .es-sidebar {
    .es-toggle {
      min-height: $sidebar-item-height-small;
      & > button {
        height: $sidebar-item-height-small;
        line-height: $sidebar-item-height-small;
      }
    }

    .es-nav-items {
      li,
      li.es-nav-item {
        min-height: es-to-rem($sidebar-item-height-small);
    
        & > a,
        & > button {
          height: es-to-rem($sidebar-item-height-small);
          line-height: es-to-rem($sidebar-item-height-small);

          &:before {
            top: $sidebar-item-chevron-position-small;
          }
        }
      }
    }
    @media (min-width: 1200px) {
      .es-nav-item ul {
        margin-top: es-to-rem($sidebar-item-height-small * -1);
      }
    }
  }  
}

side-navigation {
  display: block;
  .es-badge {
    float: right;
    margin: 1px -9px 1px -25px;
    padding: 2px 4px;
    font-weight: 700;
    min-width: 2rem;
    font-size: 1rem;
  }

  // overwrite default focus handling of sidebar
  // when screen size < desktop
  @media (max-width: 1200px) {
    .es-sidebar {
      .es-nav-item.-es-collapsible > a:focus + ul,
      .es-nav-item.-es-collapsible > button:focus + ul {
        opacity: 0;
        max-height: 0;
        overflow: hidden;
      }

      .es-nav-item.-es-collapsible > a + ul.-show-sub-nav,
      .es-nav-item.-es-collapsible > button + ul.-show-sub-nav {
        opacity: 1;
        max-height: 300px;
        overflow: inherit;
        border-top-width: 1px;
      }
    }
  }

  .es-sidebar {
    .es-nav-item ul {
      border-top-width: 0;
    }
    .es-nav-item.-es-collapsible > a:focus + ul,
    .es-nav-item.-es-collapsible > button:focus + ul {
      border-top-width: 1px;
    }
  }


  // application specific fix to make large sidebar menus accessible
  @media (max-height: 48em) {
    $sidebar-item-height-small: 35px;
    $sidebar-item-chevron-position-small: 15px;
  
    @include sidebar-patch-height($sidebar-item-height-small, $sidebar-item-chevron-position-small);
  }

  @media (max-height: 32em) {
    $sidebar-item-height-xsmall: 31px;
    $sidebar-item-chevron-position-xsmall: 12px;

    @include sidebar-patch-height($sidebar-item-height-xsmall, $sidebar-item-chevron-position-xsmall);
  }

}

