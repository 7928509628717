/*
* enrollmentGraphWidget scss
* */

enrollment-graph-widget {
  display: block;
  .hide-graph {
    #chart_container {
      .subjects {
        display: none;
      }
      .time {
        display: none;
      }
    }
  }
}
