/*
* globalMessage scss
* */

@import '../../styles/configuration';

global-message {
  display: block;

  .es-notification {
    .es-icon {
      margin-right: 10px;
    }
    h1 {
      font-size: es-to-rem($es-h4-font-size);
      line-height: es-to-rem($es-h4-font-size);
      margin-bottom: 0;
    }
    p {
      margin: 0;
      opacity: .85;
    }
  }
}
