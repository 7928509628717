.progress {
  //height: 20px;
  //margin-bottom: 20px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
}
.progress-bar {
  float: left;
  width: 0;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: #337ab7;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
  -webkit-transition: width .6s ease;
  -o-transition: width .6s ease;
  transition: width .6s ease;
}


.ert-button-progress {
  height: 2px;
  margin-left: -8px;
  margin-right: -8px;

  div {
    width: 0%;
    background-image: linear-gradient(to bottom, #3dadd5 0%, #3dadd5 100%);
    transition: none;
  }
  div.disabled {
	  visibility: hidden;
  }
}
