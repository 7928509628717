/*
* pagination scss
* */

pagination {
  @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
  display: inline-block;

  .es-icon-refresh.refreshing{
    animation: spin 4s linear infinite;
  }
}
