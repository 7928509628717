/*
* announcementsDetailsPopup scss
* */

announcements-details-popup {

	img, iframe {
		max-width: 100%;
	}
}
