/*
* dateRange scss
* */

date-range {
  display: inline-block;

  // TODO: remove when STYLE-447 is fixed
  .filter-inline {
    display: inline-block;
    margin-right: 10px;
    .es-date-input {
      position: relative;
      top: 1px;
    }
  }
}
