@import '../../../styles/configuration';

my-download-info {
	ert-popup {
		.es-popup.-es-open .es-popup-body .es-popup-footer {
			padding: 0;
		}
	}
	.ert-my-downloads-popup-loading-container {
		position: relative;
		display: block;
		height: 100px;
	}
	.es-table tbody tr.-es-selected.has-error {
		background-color: es-color('red', 'tuft-bush');
		border-left: 1px solid es-color('red', 'orange');
		border-right: 1px solid es-color('red', 'orange');
		pointer: default;
	}
	.es-table tbody tr.-es-selected.has-error>:first-child:before,
	.es-table tbody tr.-es-selected.has-error>:last-child:after {
		background-color: es-color('red', 'orange');
		bottom: 0;
		content: "";
		position: absolute;
		top: 0;
		width: .4rem;
	}
}


