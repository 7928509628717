@import '../../../../styles/configuration';

td[ert-grid-cell] {
	.hyperlink {
		&:hover {
			color: $es-anchor-hover-color;
		}
		&:hover,
		&:active {
			outline-width: 0;
		}
		font-weight: bold;
		color: $es-anchor-color;
		text-decoration: none;
	}
}
