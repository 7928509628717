ert-toolbar {
  display: block;
  ert-label {
    padding: 0.5rem;
  }
  base-select {
    padding: 0.5rem;
  }
  base-date-picker {
    padding: 0.5rem;
    position: relative;
    top: -1px;
  }
  .es-toolbar .es-separator {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: -2px;
  }
}
