@import 'configuration';
@import "global";
@import "enrollment";
@import "printStyles";

@import "app";
@import "contactus";
@import "progressBar";
@import "notifications";

//for old ert grid
@import "ertGrid";
//all css in this file need to removed after style guide is ready
@import "temporary";
@import "logistics";
@import "sqf";
@import "header";
@import "ods";
@import "ecg";
@media screen {
  @import "../design/sass/main";
  @import "variables";
  @import "spirograph";
}

@import "measurementInfo";
@import "spirometryTooltip";

@import 'ie';

@media print {
  @import "print";
}
