.help-content {
    padding: 10px;
}

.help-content p {
 font-weight: bolder;
 font-size: 15px;
}

.help-content ul {
    list-style-type: disc;
}

.help-content h4 {
    margin-top: 10px;
}