/*
* clinicalData scss
* */

clinical-data {
  display: block;
}

.signature-active #clinical-data-ui-view {
  visibility: hidden;
}
