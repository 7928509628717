ert-file-uploader {
  display: block;
  .file-item-wrapper:nth-child(2n+1){
    clear: both;
  }

  .es-file-control {
    margin-bottom: 10px;
  }

  .es-file-control {
    position: relative;
    overflow: hidden;

    .hidden-file-control {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0;
      padding: 0;
      font-size: 20px;
      cursor: pointer;
      opacity: .01;
      filter: alpha(opacity=.01);
    }
  }

}
