ert-file-item {
  display: block;
  .es-header {
    margin-bottom: 10px;
    overflow: hidden;
  }
  &.es-file-item {
    .es-file-name {
      display: block;
      width: auto;
      float: left;
      margin-right: 5px;
    }
    .es-actions {
      float: right;
      .es-loading-icon {
        float: right;
      }
    }
  }
  .info {
    margin-right: 5px;
    position: relative;
    top: -2px;
  }
}