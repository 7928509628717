table-wrap {
  display: block;
  position: relative;
  // minimal height for loading indicator
  min-height: 100px;

  //TODO: remove once STYLE-441 is resolved
  .es-table thead th {
    //padding-right: 0.3846em;
  }

  .es-table thead .es-table-sorting {
    //position: initial;
  }

  .es-table thead th:after {
    //top: 0 !important;
    //right: 5px !important;
  }
  //TODO: end: remove once STYLE-441 is resolved

  //TODO: remove or change once STYLE-442 is resolved
  .es-table thead .es-table-sorting a.sg-selected {
    color: #a2d9e8;
  }

}
