@use 'sass:math';
@import '../../styles/configuration';

.es-column.-es-border-left {
  border-left: 1px solid $es-border-light-color;
}

// helper extensions to be backwards compatible with SG 2.0.12 => MSP3 integration.
//---------------------------------..

// this extension can be replaced with %es-typo-settings-h1 in newer versions
%typo-settings-h1 {
  font-family: $es-font-headings;
  font-weight: $es-font-weight-headings;
  text-transform: uppercase;
  font-size: es-to-rem($es-h1-font-size);
  line-height: es-to-rem($es-h1-line-height);
  margin: es-to-rem($es-space-stack-default);
}



// Variables for Calendar
//----------------------------------
$calendar-width-max: 500px;

$calendar-cell-border-radius: 50%;
$calendar-cell-width: math.div(100%,7); // width for one cell => 100% / 7 => one week

$calendar-cell-color-text-default: $es-control-color;
$calendar-cell-color-background-default-hover: #eeeeee;

$calendar-cell-color-text-muted: #bcbdbc;

$calendar-cell-color-background-active: #a9e1ef;
$calendar-cell-color-background-active-hover: #d4f0f6;
$calendar-cell-color-text-active: $es-control-color;
$calendar-cell-color-text-active-hover: $es-control-color;

$calendar-cell-color-background-selected: #4ec3e0;
$calendar-cell-color-background-selected-hover: #d4f0f6;
$calendar-cell-color-text-selected: #fff;
$calendar-cell-color-text-selected-hover: $es-control-color;

$calendar-cell-color-baackground-today: #e2ea86;
$calendar-cell-color-baackground-today-hover: #f0f4c1;

$calendar-cell-separator-border: $es-container-border;

// Extension for Calendar
//----------------------------------

%es-calendar-cell {
  align-items: center;
  // border: 1px solid green;
  border: 2px solid white;
  border-radius: $calendar-cell-border-radius;
  color: $calendar-cell-color-text-default; // TODO: good idea to explicitly set the color here?
  cursor: pointer;
  display: flex;
  flex: 1 0 auto;
  height: auto;
  justify-content: center;
  text-align: center;
  user-select: none;
  width: $calendar-cell-width;

  &:before {
    content: '';
    float: left;
    padding-top: 100%;
  }
}

.es-calendar {
  width: 100%;
  padding: es-to-rem($es-space-large);
  overflow: hidden;

  .es-calendar-header {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    max-width: $calendar-width-max;
    margin: 0 auto;

    a {
      cursor: pointer;
      font-size: 32px;
      min-width: 32px;
      text-align: center;
      vertical-align: middle;
      display: inline-block;
      user-select: none;
    }

    h3 {
      font-weight: bold;
      user-select: none;
    }

    small {
      font-size: 60%;
    }

    nav {
      display: flex;
      flex-flow: row;
    }
	a.-es-muted {
		color: $calendar-cell-color-text-muted;
		cursor: default;
	}
  }

  .es-calendar-body {
    max-width: $calendar-width-max;
    margin: 0 auto;

    .es-row {
      display: flex;
      flex-flow: row;
      justify-content: space-around;
      width: 100%;

      &.-es-separator {
        border-bottom: $calendar-cell-separator-border;
        margin-bottom: es-to-rem($es-space-default);
      }
    }

    .es-day {
      @extend %es-calendar-cell;
      // default hover
      &:hover {
        background-color: $calendar-cell-color-background-default-hover;
      }

      // display day as "muted" => day not in current month
      &.-es-muted {
        color: $calendar-cell-color-text-muted;
      }
      &.-es-disabled {
		  cursor: default;
      }

      // mark day as selected or day with events
      &.-es-selected {
        background-color: $calendar-cell-color-background-selected;
        color: $calendar-cell-color-text-selected;
        font-weight: bold;

        &:hover {
          background-color: $calendar-cell-color-background-selected-hover;
          color: $calendar-cell-color-text-selected-hover;
        }
      }

      // mark day as active or day with events
      &.-es-active {
        background-color: $calendar-cell-color-background-active;
        color: $calendar-cell-color-text-active;

        &:hover {
          background-color: $calendar-cell-color-background-active-hover;
          color: $calendar-cell-color-text-active-hover;
        }
      }

      // modifier for current date/today
      &.-es-today {
        background-color: $calendar-cell-color-baackground-today;

        &:hover {
          background-color: $calendar-cell-color-baackground-today-hover;
        }
      }


      // modifiers for date ranges
      &.-es-range-start, &.-es-range-between, &.-es-range-end {
        background-color: $calendar-cell-color-background-active;
        color: $calendar-cell-color-text-active;

        &:hover {
          color: $calendar-cell-color-text-active-hover;
          background-color: $calendar-cell-color-background-active-hover;
        }
      }

      &.-es-range-between {
        border-radius: 0; // no border radius for between range markers
        border-left: none;
        border-right: none;
      }

      &.-es-range-start {
        border-radius: $calendar-cell-border-radius 0 0 $calendar-cell-border-radius;
        border-right: none;
      }

      &.-es-range-end {
        border-radius: 0 $calendar-cell-border-radius $calendar-cell-border-radius 0;
        border-left: none;
      }

      &.-es-range-start.-es-range-end {
        border-radius: $calendar-cell-border-radius;
      }


      &.-es-range-start.-es-range-end:after {
        display: none;
      }
    }

    // heading for days of week
    .es-day-of-week {
      @extend %es-calendar-cell;

      text-transform: uppercase;
      font-weight: bold;
      cursor: initial;
    }

  }
}

.es-calendar-event-view {
  @extend %es-reset-ul;

  .es-row {
    display: flex;
    flex-flow: row;
    padding-bottom: es-to-rem($es-space-default);
    margin-bottom: es-to-rem($es-space-default);
    position: relative;

    &:not(:last-child) {
      // border-bottom: $calendar-cell-separator-border;
      &:after {
        position: absolute;
        content: "";
        display: block;

        top: initial;
        bottom: 0px;
        left: -10px;
        right: -10px;
        border-bottom: $calendar-cell-separator-border;

        @include es-viewport-range(tablet-up) {
          left: -5px;
        }

      }
    }
  }

  .es-calendar-events-date  {
    @extend %typo-settings-h1;
    flex: 1 0 auto;
    padding: es-to-rem($es-space-small);
    width: 25%;

    small {
      font-weight: 300;
      font-size: 60%;
    }
  }

  .es-calendar-events {
    flex: 1 0 auto;
    padding: es-to-rem($es-space-small);
    width: 75%;

    .es-table tbody {
      border: none;
    }

    .es-table {
      & th,
      & td {
        padding: es-to-rem($es-space-large) es-to-rem($es-space-default);

        &:first-child {
          padding-left: es-to-rem($es-space-large);
        }
        &:last-child {
          padding-right: es-to-rem($es-space-large);
        }

        .es-actionbar {
          @extend %es-link-list;
        }

      }

      // align data to right
      .es-data-right {
        text-align: right;
      }

	  a.-es-muted {
		  color: $calendar-cell-color-text-muted;
		  cursor: default;
	  }

      @include es-layout-create-width-modifiers(12, "-es-data-width-");

    }
  }
}

.es-calendar-time-zone {
  color: es-color('mono', 'light-grey');
}

.es-layout-columns.-debug {
  border: 1px solid green;
}

.es-column.-es-separator {
  position: relative;


  &:before {
    position: absolute;
    content: "";
    display: block;

    top: initial;
    bottom: initial;
    left: -5px;
    right: -5px;
    border-top: $calendar-cell-separator-border;


    @include es-viewport-range(tablet-up) {
      left: initial;
      right: initial;
      top: -5px;
      bottom: -5px;
      border-left: $calendar-cell-separator-border;
    }
  }
}
