.iClinicaWrapper{
    position: fixed;
    top: 10rem;
    left: 50px;
	right:0px;
    bottom: 6.5rem;
}
.iClinicaFrame {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 6.5rem;
    height: 100%;
    width: 100%;
}
