/*
* dashboard scss
* */
@import '../../styles/configuration';

dashboard {
  display: block;
}

.es-badge-bg {
  background-color: #955276;
}