.es-form-control {
  // TODO:SG remove this after https://jira.ert.com/jira/browse/STYLE-310 is fixed
  &.-es-invalid .es-date-input input {
    border: 1px solid #ff0000;
  }
  // TODO:SG should be fixed in  https://jira.ert.com/jira/browse/STYLE-222
  .es-date-input {
    overflow: hidden;
    float: right;
    width: 60%;
  }

  // TODO:SG remove after https://jira.ert.com/jira/browse/STYLE-189 is resolved
  .es-date-input {
    input:disabled,
    input[disabled=disabled] {
      cursor: not-allowed;
      background-color: #e9e9e9;
      opacity: .7;
    }
  }

}

.es-date-input > p {
  background: #e9e9e9;
  border: 1px solid rgba(211, 211, 211, 0.3);
  border-left: none;
  border-radius: 3px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  box-sizing: border-box;
  color: #474747;
  height: 2.67857em;
  margin: 0;
  position: absolute;
  right: 0;
  width: 3em;
  font: inherit;
  cursor: pointer;
}



