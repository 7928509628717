/*
* announcementsWidget scss
* */

announcements-widget {
  display: block;

  //TODO: change or remove once design clarified MPORT-3664
  .html-content {
    height: 325px;
  }

  .es-widget-body {
    > .es-content-box {
      overflow-y: auto;
    }
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }

  .ert-complete-height {
    width: 100%;
    height: 100%;

  }
}
