#sqf-contact-wizard, #sqf-contact-summary {
	section {
		div {
			ol {
				transform: scale(0.9);
				li {
					div {
						padding-left:0.7rem;
					}
				}
			}
		}
	}
}
.ert-inline {
	display: inline;
}

@media screen and (min-width: 1080px) {
	.es-layout-columns.-es-2-column div.ert-wide-column {
		width: 70%;
	}
	.es-layout-columns.-es-2-column div.ert-narrow-column {
		width: 30%;
	}
}
@media screen and (max-width: 1199px) {
	.es-layout-columns.-es-2-column div.ert-narrow-column > div {
		width:100%;
		& > div {
			width:100%;
		}
	}
}
.ert-pull-right {
	float:right;
}
.ert-pull-right:after {
	content: '';
	display: block;
	clear:both;
}

.role-contact {
	background-color: es-color('mono', 'alto');
	margin: 10px 5px 4px 0;
	color: #727272;
}

.role-contact .filter-active-remove {
	float:right;
	width: 20px;
	height: 20px;
	display: inline-block;
	background-color: #fff;
	color: es-color('blue', 'light-blue');
	margin: 5px 5px 0 0;

	.es-icon {
		margin: 1px 3px 3px 3px;
	}
}
.role-contact .filter-active-remove
	.es-icon:hover {
		color: es-color('red', 'dark-red');

}
.role-contact .filter-active-column {
	display: inline-block;
	padding: 5px 5px 5px 10px;
}

h3 small {
	font-size:70%;
	font-weight:400;
}

div.ert-pi-panel {
	background-color: es-color('mono', 'light-grey');
}

.ert-sqf-copy-contact-icon {
	padding: .5rem .5rem .5rem 0;
	line-height: 2.2rem;
	a.es-icon {
		font-size: 1.7rem;
	}
}
.ert-sqf-contact-icon {
	line-height: 2.2rem;
	a.es-icon {
		font-size: 1.6rem;
	}
	a.es-icon.text-danger {
		color: es-color('red', 'dark-red');
	}
}

div.ert-sqf-siteinfo div.es-radio label {
	min-width:60px;
}

.ert-sqf-siteinfo .formly-field-esSgTextAreaAligned {
	padding-top: 0;
	.es-textarea {
		margin-top:-1.25rem;
	}
}

.ert-sqf-siteinfo-summary .word-break-overflow {
	max-width: 500px;
}

#sqf-roles h5 {
	margin:0;
}

.sqf-contact-card {
	&.es-card {
		.es-card-header,
		.es-card-body {
			overflow-wrap: break-word;
		}
	}
}
// workaround for the issue: new Sqf confirm dialog appears on top of edit contact information popup
.newSqfConfirmDialog {
	.es-popup.-es-open {
		z-index: 9000;
	}
}
