/*
* baseDatePicker scss
* */

base-date-picker {
  display: inline-block;

}

@media screen and (min-width: 768px) {
  .es-form-field.-es-aligned base-date-picker ,
  .es-form.-es-aligned .es-form-field base-date-picker  {
    width: 60%;
    float: right;
    .es-date-input {
      width: 100%;
    }
  }
}