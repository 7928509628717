.measurements-info {

  input:disabled,
  select:disabled{
    background: #eee;
    opacity: .9;
  }
  &.es-columns {
    overflow: visible;
  }

  .card {
    width: 100%;
  }

  .es-label {
    &.-es-hidden {
      display: none !important;
    }
  }
  .global-errors {
    clear: both;
  }

  .spirometry {
    width: 100%;
    p {
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      .number {
        width: 100%;
        float: right;
        text-align: center;
        overflow: hidden;
      }
    }
    .es-input {
      width: 100% !important;
    }

    .ng-invalid-field-comparison {
      background-color: #f5cbac;;
    }
    .formly-error-summary .es-form-message,
    .global-errors .es-form-message {
		margin-bottom: 5px;
	  width: 100%;
    }
  }
  .spirometry-trial {
    .input-wrapper {
      margin-bottom: 5px;
    }
    .es-label {
      width: auto;
      min-height: initial;
      padding: 0;
      margin: 0;
      line-height: 2.67857em;
      float: left;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .trial-label {
      display: none;
      width: 50%;
    }
    &.first {
      .trial-label {
        display: inline-block;
        float: left;
      }
      .number {
        width: 50%;
      }
      width: 22.4%;
      label {
        width: 50%;
      }
      input {
        width: 50% !important;
      }

      &.ecg {
		  width: 50%;
		  .trial-label {
			  width: 40%;
		  }
		  .number {
			  float: left;
		  }
	  }

      &.dlco {
        width: 36%;
        label.trial-label{
          width: 72%;
        }
        label {
          width: 25%;
        }
        .es-select {
            width:45%;
            float: left;
            z-index: 100;
            margin-right: 2%;
        }
        input {
          width: 28% !important;
        }
      }
    }
    width: 10.2%;
    float: left;
    margin-right: 0.5%;
  }

  .comments {
    width: 100%;
    textarea {
      width: 100%;
    }
    .es-textarea {
      width: 100%;
    }
  }
  .datepicker {
    .es-input {
      width: 100%;
    }
    .form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
      background-color: white;
    }
    .input-group-addon {
      display: none;
    }
  }

  .btn-whiteblue {
    width: 100%;
    margin-bottom: 5px;

    &.last {
      margin-bottom: 0;
    }

  }
}
