/*
* visitCalendarPopup scss
* */
visit-calendar-popup {
  display: block;

  // table and legend icons colors
  .normal-visit-color{
    color: #66c2a5 !important;
  }
  .planned-visit-color{
    color: #73bfde !important;
  }
  .overdue-visit-color{
    color: #f46d43 !important;
  }
  .unscheduled-visit-color{
    color: #b2dba6 !important;
  }
  .termination-visit-color{
    color: #d53e4f !important;
  }

  .visit-status-column {
	  margin: 0 0 0 .5rem;
  }

  ert-grid {
	  background-color: white;
  }


  .es-icon-calendar-check:after{
    color: #fff !important;
    content: "\E0CA";
    margin-left: -13px;
  }
  // end table and legend icons colors
}

