// /*
// * uploadWidget scss
// * */

@import '../../../styles/configuration';
@import '../dashboard.scss';

upload-widget {
  display: block;
  
  .html-content {
      height: 325px;
    }
  // TODO: move metrics (badges) scc to component when it is done
  .badges {
    text-align: center;
    margin: 30px 0 30px 0;
    .es-badge-label {
      display: inline-block;
      min-width: 130px;
      margin: 0 20px;
      overflow: hidden;
      padding-top: 25px;
    }
    label {
      display: block;
      margin: 0;
    }
    .es-badge {
      font-size: 5em;
      display: block;
      margin-bottom: 10px;
      line-height: 12rem;
      border-radius: 7rem;
      color: #fff;
      @extend .es-badge-bg;
    }
  }

  .es-data-text:hover {
    text-decoration: underline;
    text-underline-offset: 0.4rem;
  }

  .es-widget-body {
      > .es-content-box {
            overflow-y: auto;
          }
    }
}