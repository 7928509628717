/*
* dispenseISpiroDevicePopup scss
* */

//@import '../../styles/configuration';

dispense-ispiro-device-popup {
  display: block;
}

.height-field .inch{
  margin-left: 3px;
}

.height-field .inch-lbl, .cm-lbl {
  margin-left: 3px;
}

.height-field .height-lbl{
  width: 30% !important;
}

.height-field .es-input .ng-invalid-required{
  border: 2px solid #e5484d;
}

.age-field {
  width: 40% !important;
}
.age-input {
  width: 102% !important;
  float: left !important;
}
.age-validation-msg {
  width: 100% !important;
  float: left !important;
}
