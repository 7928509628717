/*
* grid scss
* */

grid {
  display: block;
  background: white;
}

// note: might have to check/remove this if/when style-719 is resolved
div.es-grid ul.es-fluid-data > li:not(:last-child) {
	padding-right: 10px;
}
