/*
* numberRange scss
* */

number-range {
  display: inline-block;
}

.es-toolbar number-range .es-input {
  width: 90px;
}
