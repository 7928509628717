/*
* tab scss
* */

tab {
  display: block;
}

.es-panel.tab-panel {
  display: none;
}
.es-panel.tab-panel.-es-active {
  display: block;
}
