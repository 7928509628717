ert-popup {
  @media screen and (min-width: 501px) {
  }

  .es-popup-content {
    position: relative; // limits loading indicator background to the popup block
  }

  .es-popup.-es-open {
    z-index: 9001;
  }

  .es-popup.-es-open .es-popup-body .es-popup-footer {
    padding: 0;
  }

  // Animations
  .animate.ng-enter, .animate.ng-leave {
    transition: opacity 0.3s;
    .es-popup-content {
      transition: transform 0.3s;
    }
  }

  .animate.ng-enter,
  .animate.ng-leave.ng-leave-active {
    opacity:0;
    .es-popup-content {
      transform: translateY(100vh);
    }
  }

  .animate.ng-leave,
  .animate.ng-enter.ng-enter-active {
    opacity:1;
    .es-popup-content {
      transform: translateY(0);
    }
  }
}

// hides printable contend area for popups
.ert-popup-component .printable-content {
  display: none;
}

@media print {
  .ert-popup-component {
    // hides main content during printing of popup
    &.-es-popup-opened .es-app {
      display: none;
    }

    // fixes display issue while printing
    .es-popup.-es-open {
      position: relative;
    }

    // displays printable area for popups
    .printable-content {
      display: inline;
	}
  }
}
