.es-notification-center {
  z-index: 9004;

  .es-notification p {
    word-break: normal;
  }
  &:empty {
    display: none;
  }
}
