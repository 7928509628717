.es-form-control {
  // TODO: remove after https://jira.ert.com/jira/browse/STYLE-189 is resolved
  .es-input {
    &:disabled,
    &[disabled=disabled] {
      cursor: not-allowed;
      background-color: #e9e9e9;
      opacity: .7;
    }
  }
}