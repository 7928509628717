/*
* contactsRow scss
* */

.ert-card-icons-row {
  .heading-text {
    font-weight: bold;
  }
  &.headings-aligned {
    th {
      width: auto !important;
      float: left;
      max-width: 35%;
      .es-icon {
        position: relative;
        top: -2px;
      }
    }
    th, td {
      float: left;
      max-width: 65%;
    }
  }
}
