// adds space for side navigation
@media screen and (min-width: 501px) {
  .es-app .es-app-body:not(.-es-fixed-width) {
    min-width: -webkit-calc(100% - 5.5rem) !important;
    min-width: calc(100% - 5.5rem) !important;
    margin-left: 5.5rem !important;
  }
}

// margins for small screens, when side navigation is on the top
@media (max-width: 500px) {
  .es-page-main {
    margin-left: 0;
  }
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

[ng-click],
[data-ng-click],
[x-ng-click] {
	cursor: pointer;
}

.iframe-loading-container {
	.es-panel {
		border: none;
		& > .es-content-box {
			height: 145px;
		}
	}
	padding: 5px;
}

.iframe-container {
	margin: 0 -10px -10px -10px;
	& > iframe {
		border: none;
		margin: 0;
	}
}

.full-page-iframe-container {
	margin: -5px -5px -5px -5px;
	& > iframe {
		border: none;
		margin: 0;
	}
}


//TODO remove this once style guide has correct z-index for popup
.es-popup.-es-open {
  z-index: 9001;
}

.es-tab.active {
  background: #fff;
  display: -ms-flexbox;
  display: flex;
}

// wizard numbers are disabled by css
div.es-steps {
	ol {
		li
		.disabled,
		.default,
		.current,
		.editing {
			pointer-events: none;
		}
	}
}
.icon-wrap {
	display:inline;
}

// fixes - esc viewer overlaps side bar when it is opened (MPORT-4174)
.ert-maia-container {
  z-index: 0;
}

.es-icon.flipped{
  transform: rotate(180deg);
  display: inline-block;
}
.ert-height-from-rows {
	height: auto;
}
.es-dropdown ul li a .es-icon.ert-dropdown-content-loading {
    margin: auto;
    display: block;
    top: 0.5rem;
}
