.graphWrapper {
  margin-right: 15%;
  margin-left: 15%;
}

#chart_container {
  position: relative;
  .wrapper {
    width: 60%;
    margin: 0 auto;
    position: relative;
  }
  .subjects {
    position: absolute;
    left: -20px;
    > span {
      display: inline-block;
      white-space: nowrap;
      transform: translate(0px, -100%) rotate(-90deg);
      transform-origin: 100% 100%;
      &:before {
        content: "";
        float: left;
        margin-top: 100%;
      }
    }
  }
  .time {
    float: right;
  }
}

#chart {
  margin-left: 40px;
}

#y_axis {
  position: absolute;
  height: 100%;
  width: 40px;
  overflow: hidden;
}

#x_axis {
  height: 30px;
  margin-left: 40px;
}

.default_legend {
  display: inline-block;
  text-align: left;
  opacity: 0.8;
  width: 100%;
  color: black;
  font-size: 100%;
  font-weight: bold;
  text-decoration: underline;
  padding: 10px 0 0 40px;

  .legend_item {
    float: left;
    margin-right: 10px;

    .swatch {
      display: inline-block;
      width: 10px;
      height: 10px;
      margin: 0 8px 0 0;
    }

    .item_title {
      display: inline-block;
      font-size: 75%;
      white-space: nowrap;
    }
  }
}

.rickshaw_graph .detail {
  background: none;
  background-color: #0175AF;
}

.rickshaw_graph .y_ticks .tick,
.rickshaw_graph .x_ticks_d3 .tick {
  stroke: white;
  fill: white;
  fill-opacity: 0.8;
  stroke-opacity: 0.3;
  stroke-width: 0.75px;
  shape-rendering: auto;
  pointer-events: none;
}

.rickshaw_graph .y_ticks text,
.rickshaw_graph .x_ticks_d3 text {
  opacity: 1;
}

.rickshaw_graph .y_ticks .domain,
.rickshaw_graph .x_ticks_d3 .domain {
  stroke: white;
  stroke-opacity: 0.8;
}

#chart_container {
  color: black;
  overflow: hidden;
  padding: 15px 0 10px;
}

 .rickshaw_graph .y_ticks .domain,
 .rickshaw_graph .x_ticks_d3 .domain {
  stroke: black;
  stroke-opacity: 0.8;
}

 .rickshaw_graph .y_ticks .tick,
 .rickshaw_graph .x_ticks_d3 .tick {
  stroke: black;
  fill: black;
  fill-opacity: 0.8;
  stroke-opacity: 0.3;
  stroke-width: 0.75px;
  shape-rendering: auto;
  pointer-events: none;
}

.rickshaw_graph .detail {
  pointer-events: none;
  position: absolute;
  top: 0;
  z-index: 2;
  background: rgba(0, 0, 0, 0.1);
  bottom: 0;
  width: 1px;
  transition: opacity 0.25s linear;
  -moz-transition: opacity 0.25s linear;
  -o-transition: opacity 0.25s linear;
  -webkit-transition: opacity 0.25s linear;
}

.rickshaw_graph .detail.inactive {
  opacity: 0;
}

.rickshaw_graph .detail .item.active {
  opacity: 1;
}

.rickshaw_graph .detail .x_label {
  font-family: Arial, sans-serif;
  border-radius: 3px;
  padding: 6px;
  opacity: 0.5;
  border: 1px solid #e0e0e0;
  font-size: 12px;
  position: absolute;
  background: white;
  white-space: nowrap;
  display: none;
}

.rickshaw_graph .detail .x_label.left {
  left: 0;
}

.rickshaw_graph .detail .x_label.right {
  right: 0;
}

.rickshaw_graph .detail .item {
  position: absolute;
  z-index: 2;
  border-radius: 3px;
  padding: 0.5em;
  font-size: 12px;
  font-family: Arial, sans-serif;
  opacity: 0;
  background: rgba(0, 0, 0, 0.4);
  color: rgb(96, 96, 96);
  border: 1px solid rgba(0, 0, 0, 0.4);
  margin-left: 1em;
  margin-right: 1em;
  margin-top: -1em;
  white-space: nowrap;
  line-height: 1.4;
  min-width: 150px;
}

.rickshaw_graph .detail .item.left {
  left: 0;
}

.rickshaw_graph .detail .item.right {
  right: 0;
}

.rickshaw_graph .detail .item.active {
  opacity: 1;
  background: rgba(255, 255, 255, 0.8);
}

.rickshaw_graph .detail .item:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  content: "";
  border: 5px solid transparent;
}

.rickshaw_graph .detail .item.left:after {
  top: 1em;
  left: -5px;
  margin-top: -5px;
  border-right-color: rgba(0, 0, 0, 0.8);
  border-left-width: 0;
}

.rickshaw_graph .detail .item.right:after {
  top: 1em;
  right: -5px;
  margin-top: -5px;
  border-left-color: rgba(0, 0, 0, 0.8);
  border-right-width: 0;
}

.rickshaw_graph .detail .dot {
  width: 4px;
  height: 4px;
  margin-left: -3px;
  margin-top: -3.5px;
  border-radius: 5px;
  position: absolute;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  background: white;
  border-width: 2px;
  border-style: solid;
  display: none;
  background-clip: padding-box;
}

.rickshaw_graph .detail .dot.active {
  display: block;
}

.detail_swatch {
  float: right;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 4px 0 0
}

.rickshaw_graph .detail .date {
  color: #a0a0a0;
}
