/*
* baseTimePicker scss
* */

//@import '../../styles/configuration';

base-time-picker {
  display: inline-block;

  .es-date-input:after {
    content: "";
  }

  .es-icon.es-icon-content-time {
    padding: .5rem 1rem;
    position: absolute;
    right: 0;
    top: 0.1rem;
    bottom: 0;
    line-height: 1.5em;
  }

  .es-date-input.-es-invalid .es-icon{
    color: #be3a34;
  }
}


@media screen and (min-width: 768px) {
  .es-form-field.-es-aligned base-time-picker ,
  .es-form.-es-aligned .es-form-field base-time-picker  {
    width: 60%;
    float: right;
    .es-date-input {
      width: 100%;
    }
  }
}