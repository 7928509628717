.es-collapsible-panel {
  .es-collapsible-panel-body {
    &.collapse {
      display: none;
      &.in {
        display: block;
      }
    }
  }
}

.ert-grid-filter-overdue {
	color: es-color('red', 'dark-red');
}
.ert-grid-duration-column {
	min-width: 85px;
}

//todo will remove after ert style fix the issues
.es-table th{
    .es-checkbox input:checked+label:after{
      color: black;
    }
}
// todo remove if style guide supports button + dropdown,
// and the new style guide will include this soon
.es-toolbar-item{

    .es-dropdown > .es-button {
      border: 1px solid;
      border-color: #0092bc;
      padding: 0.5rem 1rem;
      &:disabled {
        border-color: #bcbdbc;
      }
    }

    .es-tag {
      .divider {
        color: white;
      }
      &-es-close:after {
        vertical-align: middle;
      }
    }
}

//ert grid tooltip
.ert-grid-tooltip{
  position: relative;
  font-size: 12px;
  display: inline-block;
  text-transform: none;
}
.ert-grid-tooltip:hover:after{
  background: es-color('clario-mono', 'gray-12');
  bottom: 31px;
  color: #fff;
  content: attr(title);
  left: 10%;
  padding: 5px 15px;
  position: absolute;
  z-index: 98;
  width: 120px;
  white-space: normal !important;
}
.ert-grid-tooltip:hover:before{
  border: solid;
  border-color: es-color('clario-mono', 'gray-12') transparent;
  border-width: 6px 6px 0 6px;
  bottom: 26px;
  content: "";
  left: 20%;
  position: absolute;
  z-index: 99;
}
