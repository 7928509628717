/*
* widget scss
* */

widget {
  display: block;
  // TODO: remove this if style-guide provides spacing
  padding-bottom: 20px;
  .es-widget .es-widget-header {
    // fixes: button overlaps widget header while heading text is loading
    line-height: 2.8rem; //TODO: create bug in SG
  }
}

@media print {
  widget {
    page-break-after: always;
  }
}
