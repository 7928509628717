loading-indicator {
  .-standalone {
    margin: 50px 0;
  }
  .es-loading-indicator.-es-fullscreen {
    z-index: 9003;
  }
}
.global-loading {
	loading-indicator {
		div:not(.-es-fullscreen) {
			visibility: hidden;
		}
	}
}
