/*
 * filterBadges scss
 * */

filter-badges {
	display: block;
	.divider {
		color: white;
	}
	.es-tag.-es-close:after {
		vertical-align: middle;
	}
}
