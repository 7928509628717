/*
* page scss
* */

//@import '../../styles/configuration';

page {
  display: block;
  ert-button {
    float:right;
  }
}
