/*
* visitCalendarLegend scss
* */

visit-calendar-legend {
  display: block;
  .legend-title {
	  font-weight: bold;
  }
  .es-bullet-list {
	  margin-top: .5rem;
  }
}
