preview-popup {
	.es-popup.-es-open .es-popup-content {
		background: transparent;
    box-shadow: none;
    top: 50%;
    transform: translateY(-50%);
    margin-top: 0;
    margin-bottom: 0;
	}
}
