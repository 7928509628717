.spirochart-container-width {
  width: 100%;
  margin-right: 0px !important;
  padding-right: 0px !important;
  //overflow-x: auto;
  height: 96%;
}

.ert-deselected-param {
  color: es-color('mono','light-grey');
}

.spirochart {
    position: relative;
    left: 15px !important;
    display: block;
    //height: 340px !important;
    //width: 460px !important;
  }

.large-view .spirochart{
  height: 75vh;
}

@include breakpoint(min-notebook) {
  .large-view .md-content{
    max-height: 100vh;
    min-height: 20px;
  }
}

.axis path,
.axis line {
  fill: none;
  stroke: #000;
  shape-rendering: crispEdges;
}
.axis text {
  color: #000;
}

.trial {
	fill: none;
  	stroke: #000;
}

.ert-error-code-table{
  margin: 0 !important;
}

.panels-block{
  .panel-heading{
    padding-left: 0;
  }
}

.spir-table tr .th-nhp-1.active, .spir-table tr .th-nhp-1.active-mobile {
  background-color: $trial1Color !important;
  border-radius: 0;
  color: #fff;
}
.spir-table tr .th-nhp-2.active, .spir-table tr .th-nhp-2.active-mobile  {
  background-color: $trial2Color !important;
  border-radius: 0;
  color: #fff;
}
.spir-table tr .th-nhp-3.active, .spir-table tr .th-nhp-3.active-mobile  {
  background-color: $trial3Color !important;
  border-radius: 0;
  color: #fff;
}
.spir-table tr .th-nhp-4.active, .spir-table tr .th-nhp-4.active-mobile  {
  background-color: $trial4Color !important;
  border-radius: 0;
  color: #fff;
}
.spir-table tr .th-nhp-5.active, .spir-table tr .th-nhp-5.active-mobile  {
  background-color: $trial5Color !important;
  border-radius: 0;
  color: #fff;
}
.spir-table tr .th-nhp-6.active, .spir-table tr .th-nhp-6.active-mobile  {
  background-color: $trial6Color !important;
  border-radius: 0;
  color: #fff;
}
.spir-table tr .th-nhp-7.active, .spir-table tr .th-nhp-7.active-mobile  {
  background-color: $trial7Color !important;
  border-radius: 0;
  color: #fff;
}
.spir-table tr .th-nhp-8.active, .spir-table tr .th-nhp-8.active-mobile  {
  background-color: $trial8Color !important;
  border-radius: 0;
  color: #fff;
}

.spir-table tr:last-child{
  height: 34px;
}
@include breakpoint(tablet) {
  .spir-table tr:last-child{
    height: auto;
  }
}

.spir-table tr:last-child .td-nhp-1.active {
  border-bottom: 4px solid $trial1Color;
}
.spir-table tr:last-child .td-nhp-2.active {
  border-bottom: 4px solid $trial2Color;
}
.spir-table tr:last-child .td-nhp-3.active {
  border-bottom: 4px solid $trial3Color;
}
.spir-table tr:last-child .td-nhp-4.active {
  border-bottom: 4px solid $trial4Color;
}
.spir-table tr:last-child .td-nhp-5.active {
  border-bottom: 4px solid $trial5Color;
}
.spir-table tr:last-child .td-nhp-6.active {
  border-bottom: 4px solid $trial6Color;
}
.spir-table tr:last-child .td-nhp-7.active {
  border-bottom: 4px solid $trial7Color;
}
.spir-table tr:last-child .td-nhp-8.active {
  border-bottom: 4px solid $trial8Color;
}

.ert-spirometry-chart-info{
  overflow-x: auto !important;
}

.ert-spirograph-axis{
		path,line {
				stroke: gray;
				stroke-width: 2;
		}
		text {
				font-family: $font-family-noto-sans;
				font-size: 12px;
		}
		font-family: $font-family-noto-sans;
		font-size: 12px;
		fill: gray;
}
.ert-spirograph-error {
		fill: red;
}

.spir-table tr .ert-th-deselected  {
		border-bottom-style: dashed;
}
.spir-table .ert-deselected-label {
		color: $text-color;
		font-style: italic;
		display: block;
}
.spir-table .th-nhp.active .ert-deselected-label
{
  color: #fff;
}

@include breakpoint(tablet) {
    .spir-table tr .ert-th-deselected {
				border-right-style: dashed;
    }
}
