report-review-popup ert-popup {

	// fix
	.es-form.-es-aligned .es-form-field .es-group .es-checkbox {
		width: auto;
	}
	.es-form .es-form-field .es-group .es-form-message,
	.es-form .es-form-field .es-group base-date-picker {
		width: 100%;
	}


	#report-count-info p span {
		line-height: 24px;
	}
	@media screen and (max-width: 899px) {
		.es-form-field .es-date-input,
		.form-grid-container {
			width: 100%;
		}
	}

	ert-radio-button-group {
		.es-form-message {
			margin: .5rem 0 0 !important;
		}

		.es-form-field table .es-checkbox {
			margin: 0px !important;
			padding: 0px !important;
		}
	}

	.ert-table-in-radio {
		max-height: 200px;
		overflow-y: scroll;
		width: 100%;
	}


}

