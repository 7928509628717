label.badge-label-before {
	float: left;
}

label.badge-label-after {
	float: right;
	padding-left: 5px;
}

span.badge-label {
	display: inline-block;
}
