//todo all css in this file will be removed after style guide is ready

.-es-bg-data{
  .es-checkbox input:checked+label:after{
    color: black;
  }
}

.es-select select:required:invalid {
	color: black;
}

// required for nested popups when parent popup is closeable
// but child popup is not (style guide change pending to support this case without this special class)
.es-popup.-es-open.ert-not-closeable .es-popup-header {
	padding-left: 1rem;
}
