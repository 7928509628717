/*
* exportButton scss
* */

export-button {
  display: inline-block;
  // todo remove if style guide supports button + dropdown
  .es-dropdown > .es-button {
    border: 1px solid;
    border-color: #0092bc;
    padding: 0.5rem 1rem;
    &:disabled {
      border-color: #bcbdbc;
    }
  }

  .-es-open.es-dropdown ul {
    left: -100%;
    right: 0;
  }
}
