/*
* loadingRow scss
* */

.loading-row {
  // when loading first time
  height: 100px;
}

// prevents hover colors for loading row
.es-table.-es-selectable tbody tr.loading-row:hover {
  background-color: #f9f9f9;
}

// when reloading data in table
tr ~ tr.loading-row {
  position: relative;
  height: 0;
   td {
     padding: 0;
   }
}
