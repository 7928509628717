#new-order-wizard {
	min-height: 300px;
	.items-table {
		th {
			width: 20%;
		};
		td.es-data-text .es-form-field {
			padding: 0px;
		}
	}
	#neworder-summary-signature div.es-signature {
		header {
			div {
				padding: 0px;
			}
		}
		& > div {
			background-color: white;
			padding: 1rem;
		}
	}
}
