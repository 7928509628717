/*
* countries scss
* */

clinical-data-subjects {
  display: block;

  .input-group-addon {
    width: 60%;
    float: right;
    display: flex;
    .es-input{
      flex: 1 !important;
    }
  }
}
