.ert-ng-wizard-toolbar {
  &.es-toolbar {
    h3 {
      display: inline-block;
      margin: 0;
      line-height: 60px;
      padding-left: 10px
    }
    .-es-right {
      margin: 0;
    }
  }
}