.es-section-box {
  &.-es-bg-data {
    table {
      color: #494a4b;
    }
  }
}

.ert-site-info {
		.ert-site-info-cards {
				section.es-card {
						min-height: 170px;

				}
		}
		.ert-user-info-cards {
				section.es-card {
						min-height: 160px;

				}
				h4 {
						word-wrap:break-word;
				}
				.first {
						clear: both;
				}
		}
}
