/*********************
BREAKPOINTS
*********************/

//---------------------------------------------------




//----------------------------------------------------------
// Mixin: ert-viewport-range
//----------------------------------------------------------
@mixin ert-viewport-range(
	$viewport-name
)
{
	// Constants
	$min: min-width;
	$max: max-width;
	$screen-device-constraint: "screen";

	// prepare media query
	$media-query: $screen-device-constraint;

	// get viewport settings (min-max-range)
	$viewport-settings: map-get($ert-viewport-ranges, $viewport-name);

	// expect always two parameters
	@if length($viewport-settings) != 2 {
		@error "Min- and maximum value for #{$viewport-name} must be specified.";
	}

	// get minimum and maximum value from the settings
	$viewport-min: nth($viewport-settings, 1); // minimum value
	$viewport-max: nth($viewport-settings, 2); // maximum value

	// minimum value specified?
	@if $viewport-min {
		// append min
		$media-query: $media-query + " and (" + $min + ": " + $viewport-min + ")";
	}

	@if $viewport-max {
		// append max
		$media-query: $media-query + " and (" + $max + ": " + $viewport-max + ")";
	}

	// output media query
	@media #{$media-query}
	{
		@content;
	}
}
//DEPRECATED do not use!
@mixin breakpoint($point) {
	@if $point == desktop {
		@media (min-width: $breakpoint-desktop) {
			@content;
		}
	} @else if $point == min-notebook {
		@media (min-width: $breakpoint-notebook + 1px) {
			@content;
		}
	} @else if $point == notebook {
		@media (max-width: $breakpoint-notebook) {
			@content;
		}
	} @else if $point == min-tablet {
		@media (min-width: $breakpoint-tablet + 1px) {
			@content;
		}
	} @else if $point == tablet {
		@media (max-width: $breakpoint-tablet) {
			@content;
		}
	} @else if $point == min-mobile {
		@media (min-width: $breakpoint-mobile + 1px) {
			@content;
		}
	} @else if $point == mobile {
		@media (max-width: $breakpoint-mobile) {
			@content;
		}
	} @else if $point == mobile-small {
		@media (max-width: $breakpoint-mobile-small) {
			@content;
		}
	}
}

@mixin ert-icons {
	font-family: "ert-icons" !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin nav-item($height) {
	min-height: $height;

	button, a {
		display: block;
		height: $height;
		line-height: $height;
		vertical-align: middle;
		width: 100%;
		white-space: nowrap;
		* {
			display: inline-block;
		}
	}

	button {
		background: transparent;
		border: none;
		cursor: pointer;
	}

	button:focus {
		outline: none;
	}
}

@mixin ert-icon-shadow($width, $color, $base-color, $shadow-color) {
	position: relative;
	width: $width;
	height: $width;

	span {
		display: inline-block;
		position: absolute;
		width: $width;
		height: $width;
		line-height: $width;
		font-size: $width;
	}

	.icon {
		color: $color;
	}
	.icon-shadow {
		color: $shadow-color;
	}
	.icon-base {
		color: $base-color;
	}
}

@mixin transition-collapse() {
	opacity: 0;
	max-height: 0;
	overflow: hidden;
}

@mixin transition-expand($height) {
	opacity: 1;
	max-height: $height;
	overflow: inherit;
}

//copied from bootstrap 3
@mixin btn-disabled {
	cursor: not-allowed;
	opacity: 0.65;
	filter: alpha(opacity=65);
	-webkit-box-shadow: none;
	box-shadow: none;
}
