.content {
  min-height: calc(100vh - 115px);
  padding: 15px 15px 0 75px;
}

.loading-wrapper {
  position: relative;
  min-height: 80px;
}

// fixes file upload button
.hidden-file-control-fix {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: .01;
  filter: alpha(opacity=.01);
  width: 100%;
  height: 100%;
}

.ert-state-change-loading-panel {
	width: 100%;
	height: 100%;
	position: absolute;
}

.ml-10 { margin-left:10px; }

.top5 { margin-top:5px; }
.top10 { margin-top:10px; }
.top15 { margin-top:15px; }
.top20 { margin-top:20px; }
.top30 { margin-top:30px; }
.top100 { margin-top:100px; }

.btm5 { margin-bottom:5px; }
.btm10 { margin-bottom:10px; }
.btm15 { margin-bottom:15px; }
.btm20 { margin-bottom:20px; }
.btm30 { margin-bottom:30px; }
.btm100 { margin-bottom:100px; }

.no-margin{
  margin:0 !important;
}

.word-wrap {
	word-wrap: break-word;
}

.word-break-overflow {
	overflow-wrap: break-word;
}

.centered{
  float: none;
  margin: 0 auto;
}

.center {
  display: flex;
  justify-content: center;
}

.form-right-column {
  float: right;
  width: 60%;
  padding-top: 0.53571em;
}
.display-flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

.flex-5 {
  flex: 5;
}

.flex-6 {
  flex: 6;
}

[class*="flex-"] {
  padding-left: 10px;
  padding-right: 10px;
}

[class*="flex-"]:first-child {
  padding-left: 0;
}

[class*="flex-"]:nth-last-child(2) {
  padding-right: 0;
}

.full-width {
  width:100% !important;
}

.clearfix {
  &:before,
  &:after {
    content: " "; // 1
    display: table; // 2
  }
  &:after {
    clear: both;
  }
}

.hide-gsso-menu div[data-gh-flyouts]{
      display: none !important;
}

// Alignment
.text-left           { text-align: left; }
.text-right          { text-align: right; }
.text-center         { text-align: center; }
.text-justify        { text-align: justify; }
.text-nowrap         { white-space: nowrap; }

// Transformation
.text-lowercase      { text-transform: lowercase; }
.text-uppercase      { text-transform: uppercase; }
.text-capitalize     { text-transform: capitalize; }

//float
.float-left {
  float: left !important;
}
.float-right {
  float: right !important;
}
.float-none {
  float: none !important;
}

//touch devices
.touch-only {
	display: none!important;
}
.touch .touch-only {
	display: inherit!important;
}
.touch .no-touch {
	display: none!important;
}

.es-form-field .es-checkbox, .es-form-field .es-radio, .es-toolbar .es-checkbox, .es-toolbar .es-radio {
	padding: .6rem 0 !important;
}

.es-file-item {
  background-color: white;
}

//add global required style
.red-star:after {
  color: #dd4c3d;
  content: " *";
  font-weight: bold;
}

.ert-form-field .es-form-message {
  margin: .5rem 0 0 0;
}

// fixes missing styles of sg file app_with_sidebar
.es-app .es-sidebar {
  z-index: 990 !important;
}

//style for auto-complete textbox --START

.autocomplete .chip {
  align-items: center;
  background-color: #e9e9e9;
  color: #333;
  border-radius: 6px;
  padding: 3px 7px;
  cursor: pointer;
  position: absolute;
  margin-top: -27px;
  z-index: 1;
}

.autocomplete .chip-main {
  margin-left: 194px;
  @extend .chip;
}

.autocomplete .chip-extra {
  margin-left: 300px;
  @extend .chip;
}

.autocomplete .chip .remove-icon {
  margin-left: 4px;
  font-weight: bold;
}

.autocomplete input {
  margin-top: -30px;
}

.autocomplete .options-wrapper {
  position: absolute;
  top: 63%;
  left: 0;
  width: 29%;
  max-height: 250px;
  overflow-y: auto;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 4px;
  z-index: 1;
  margin-left: 697px;
}

.autocomplete .options-wrapper .option-item {
  margin-bottom: 4px;
  padding: 4px;
  cursor: pointer;
  background-color: #fff;
  border-radius: 4px;
}

.autocomplete .options-wrapper .header {
    border-bottom: 1px solid #ccc;
    color: #939191;
  }

.autocomplete .options-wrapper .option-item:hover {
  background-color: #f0f0f0;
}
.autocomplete .options-wrapper a{
  float: right;
}

.autocomplete .autocomplete-validation span{
  color: #dd4c3d !important;
  margin-left: 193px;
}
//style for auto-complete textbox --END
.user_confirmation_lbl{
  margin: 0 0 0 0 !important;
}
.disclaimer {
  color: #dd4c3d !important;
}
.custom-checkbox-inline {
  width: 125%;
}

.es-file-item {
  .icon-wrap.prefix-icons {
    .es-icon.-es-disabled {
      opacity: .5;
      cursor: not-allowed;
      pointer-events: none;
    }
  }
}
