/*
* visitCalendarBreadcrumbs scss
* */

visit-calendar-breadcrumbs {
  display: block;
  .es-dropdown a {
	  cursor: pointer;
  }
}
