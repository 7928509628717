/*
 * badge scss
 * */

@import '../../styles/configuration';

badge {
	display: block;

	.es-badge {
		&.-es-info {
			background-color: es-color('blue', 'light-blue');
		}
		&.-es-success {
			background-color: es-color('turquoise', 'mint');
		}
		&.-es-warning {
			background-color: es-color('red', 'carrot');
		}
		&.-es-danger {
			background-color: es-color('red', 'dark-red');
		}

	}

	.es-icon {
		display: inline-block;
		top: -.1rem;
		position: relative;
	}
	.badge-content {
		&:before {
			content: " - ";
		}
	}
}
