/*
* contentMessage scss
* */

@import '../../styles/configuration';

content-message {
  .es-content-message {
    display: block;
    padding: 0 10px;
    margin-top: 10px;


    .es-icon {
      position: relative;
      top: -2px;
      font-size: 1.5em;
      margin-right: 1rem;
    }
    .es-icon-info {
      color: $es-info-color;
    }
    .es-icon-alert-warning{
      color: $es-warning-color;
    }

    .es-icon-alert-success {
      color: $es-success-color;
    }

    .es-icon-alert-danger {
      color: $es-danger-color;
    }
    .es-icon-bell {
      color: $es-inactive-color;
    }

    &.-es-centered {
      text-align: center;
      .es-icon {
        display: block;
      }
    }
  }
}
