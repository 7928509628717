dcr-details-popup {
	.fixed-layout {
		table-layout: fixed;
		overflow-wrap: break-word;
		word-wrap: break-word;
	}

	@media print {
		.ert-print-only {
			display: initial;
		}
	}

	@media screen {
		.ert-print-only {
			display: none;
		}
	}
}
