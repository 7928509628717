/*
* countriesInfoPopup scss
* */

i-spiro-popup {
  display: block;
  img {
	  display: block;
	  width: 100%;
  }
}
