/*
* fileUploadPopup scss
* */

@import '../../../styles/configuration';

file-upload-popup {
  display: block;

  .drop-zone { border: dotted 3px lightgray;
    height: 100px;
    margin-bottom: 10px;
  }

  .nv-file-over { border: dotted 3px red; } /* Default class applied to drop zones on over */

  .file-item-wrapper:nth-child(2n+1) {
    clear: both;
  }

  .es-file-control {
    position: relative;
    overflow: hidden;

    .hidden-file-control {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0;
      padding: 0;
      font-size: 20px;
      cursor: pointer;
      opacity: .01;
      filter: alpha(opacity=.01);
    }
  }

  .es-file-item {
    position: relative;
  }

  .es-icon-redo,
  .es-icon-row-close,
  .es-icon-download {
    color: #0092bc;
  }

  .icon-flipped {
    transform: scaleX(-1);
  }

  .es-progress {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.1);

    .es-progress-bar {
      height: .2rem;
      padding: 0;
      overflow: hidden;
      border-radius: 0;
      transition-duration: 300ms;
      width: 50%;
      background: #148f80;
      transition: width .1s ease, background-color .1s ease;
    }
  }

  @keyframes rot {
    from {
      transform: rotate(0deg)
      translate(-1px)
      rotate(0deg);
    }
    to {
      transform: rotate(360deg)
      translate(-1px)
      rotate(-360deg);
    }
  }

  .es-icon-magnifier {
    position: relative;
    top: -1px;
    animation: rot 1s infinite linear;
  }
}
