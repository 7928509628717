.print-only {
  display: none;
}

@media print {
  // This fixes issue when the printed content is cut on print view
  * {
    overflow: visible !important;
    float: none !important;
  }
  // End This fixes issue when the printed content is cut on print view

  .print-only {
    display: initial;
  }

  .no-print {
    display: none;
  }

  // removes side navigation margin
  .es-page-main {
    margin-left: 0;
  }

  // print styles hook for ert-modal, ert-modal-form and ert-sg-modal-wizard
  .ert-print-selective {
    visibility: hidden;
    ert-grid {
      display: none !important;
    }
    .ert-print-area {
      ert-grid {
        display: block;
      }
      visibility: visible;
      .es-popup,
      &.es-popup {
        position: absolute;
        top: 0;
        left: 0;
        //some guys will nest modals.
        .es-popup{
          visibility: visible;
          position: absolute;
          top: inherit;
        }
      }
    }
  }

}

