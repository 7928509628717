/*
* ecoaSessionViewer scss
* */

@import '../../../styles/configuration';

ecoa-session-viewer {
  display: block;

  .holiday-mode {
	  display: inline-block;
	  & > div {
		  display: inline-block;
		  padding-left: 5px;
	  }

	  [class^="icon-"], [class*=" icon-"] {
		  /* use !important to prevent issues with browser extensions that change fonts */
		  font-family: 'icomoon' !important;
		  speak: none;
		  font-style: normal;
		  font-weight: normal;
		  font-variant: normal;
		  text-transform: none;
		  line-height: 1;

		  /* Better Font Rendering =========== */
		  -webkit-font-smoothing: antialiased;
		  -moz-osx-font-smoothing: grayscale;
	  }

	  .es-icon-vacation-mode {
		  font-size: 1.7rem;
	  }
  }

  .list-item {
	  font-size: 1.4rem;
	  line-height: 3rem;
	  color: es-color('blue', 'light-blue');
  }
  badge {
	  display: inline-block;
  }

  .date-list {
	  tr {
		  vertical-align: middle !important;
		  td:last-child {
			  text-align: right;
			  vertical-align: middle !important;
		  }
	  }
  }

  .es-bullet-list .es-icon {
      display: inline-block;
      top: -.1rem;
      position: relative;
  }

  .border-container {
      border: 1px solid es-color('mono', 'alto');
  }

  /* tablet-up */
  @media (min-width: 768px) {
	  .ecoa-columns-structure .es-column {
		  width: 215px;
	  }
	  .ecoa-columns-structure .es-column + .es-column {
		  width: calc(100% - 215px);
	  }

	  .ecoa-columns-alerts-structure .es-column {
		  width: 60%;
	  }
	  .ecoa-columns-alerts-structure .es-column + .es-column {
		  width: 40%;
	  }
  }

  @media (max-width: 767px) {
	  .es-grid table.parameter-table {
		  width: 100%;
	  }
  }

  .session-summary {
	  ul:first-child {
		  padding-left: 5px;
		  font-size: 1.6rem;
	  }
	  ul:last-child {
		  padding-right: 5px;
	  }
  }

  .es-grid table.parameter-table {
	  width: 50%;
	  th {
		  min-width: 68px
	  }

	  &.one th {
		  width: 50%
	  }
	  .two th {
		  width: 33.3%;
	  }
	  &.three th {
		  width: 25%
	  }
	  .four th {
		  width: 20%;
	  }
	  .five th {
		  width: 16.6%;
	  }
  }

  .es-toolbar-item badge,
  .answer-group .es-radio {
	  padding: .6rem 0;
  }

  .es-grid-title {
	  width: 30% !important;
  }
  .es-grid-badges {
	  width: 10% !important;
	  .es-badge {
		  font-size: 1.3rem;
	  }
  }
  .es-grid-info {
	  width: 60% !important;
	  padding-right: 25px;
	  li {
		  width: 50%;
	  }
	  .es-icon {
		  &.-es-info {
			  color: es-color('blue', 'light-blue');
		  }
		  &.-es-success {
			  color: es-color('turquoise', 'mint');
		  }
		  &.-es-warning {
			  color: es-color('red', 'carrot');
		  }
		  &.-es-danger {
			  color: es-color('red', 'dark-red');
		  }
	  }
  }
  .session-module {
	  thead th {
		  &:first-child {
			  width: 60%;
		  }
		  &:last-child {
			  width: 40%;
		  }
	  }
  }

  .es-grid {
	  .es-collapsible-panel-header {
		  &.es-grid-title,
		  &.es-grid-info,
		  &.es-grid-badges {
			  width: 33.3%;
		  }
	  }
	  .es-grid-body {
		  padding: .5rem;
	  }
	  table {
		  width: 100%;
		  tbody {
			  tr {
				  &.list-selected {
					  background-color: es-color('blue', 'polar');
				  }
			  }
		  }
	  }
  }


}
