//stuff we want to remove for print:
//-----------------------
ert-list-filter,
.ert-no-print,
.ert-sidebar,
ert-tab-navigation,
  //all navbars
.navbar,
  //toasts
.wizard-steps {
  display: none !important;
}

* {
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

@page {
  size: auto;   /* auto is the initial value */
  /* this affects the margin in the printer settings */
  margin: 20mm 10mm 30mm 10mm;
}

html, body {
  zoom: 90%;
  height: 90%;
  margin: 0 0 0 0;
  font-family: 'Noto Sans', sans-serif;
  font-size: 12pt;
}

body {
  /* this affects the margin on the content before sending to printer */
  margin: 0;
  color: #000;
  background: #fff;
}


// fixes missing printing style for tables
// TODO: remove in the scope of MPORT-4760
.es-table {
  .fixed-layout {
    table-layout: fixed;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }
  thead,
  tbody {
    border: 1px solid black !important;
  }
  tr {
    color: black !important;
    background-color: white !important;
  }
}