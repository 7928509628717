//----------------------------------------------------------
// Spirometry styling
//----------------------------------------------------------
  .spir-table-overlay {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    background: #fff;
  }
  .spir-table-overlay table {
	  .es-icon {
		  font-size: $es-icon-size-large;
	  }
    border-collapse:separate;
    width: 100%;
  }
  .spir-table tr .thead {
    vertical-align: top;
    border-left: 2px solid #fff;
    border-right: 2px solid #fff;
    text-transform: none;
    padding-top:10px;
  }
  //.spir-table tr .thead:first-child {
  //	color: #b5d134;
  //  vertical-align: middle;
  //}
  .spir-table tr  .th-nhp {
    background-color: #fafafa;
    border-radius: 4px;
    min-width: 92px;
    text-align: center
  }
  .spir-table .th-nhp .icon {
    display: block;
  }
  .spir-table .th-nhp-1 {
    border-bottom: 4px solid $trial1Color;
  }
  .spir-table .th-nhp-2 {
    border-bottom: 4px solid $trial2Color;
  }
  .spir-table .th-nhp-3 {
    border-bottom: 4px solid $trial3Color;
  }
  .spir-table .th-nhp-4 {
    border-bottom: 4px solid $trial4Color;
  }
  .spir-table .th-nhp-5 {
    border-bottom: 4px solid $trial5Color;
  }
  .spir-table .th-nhp-6 {
    border-bottom: 4px solid $trial6Color;
  }
  .spir-table .th-nhp-7 {
    border-bottom: 4px solid $trial7Color;
  }
  .spir-table .th-nhp-8 {
    border-bottom: 4px solid $trial8Color;
  }

  .spir-table tr:nth-child(even) {
    background-color: #ebebeb;
    td {
      outline: 2px solid #ebebeb;
    }
  }
  .spir-table td {
    padding: 5px 0 5px 5px;
    vertical-align: middle;
  }


  .spir-table tr:first-child .active {
    background-color: #498ec9 !important;
    border: #498ec9;
    border-radius: 0;
    color: #fff;
  }
  .spir-table tr:nth-child(odd) .active {
    background-color: #edeef0;
  }
  .spir-table tr:nth-child(even) .active {
    background-color: #dadbdd;
  }
  .spir-table tr:last-child .active {
    border-bottom: 4px solid #498ec9;
  }
  .spir-table td .icon {
    vertical-align: middle;
  }
  .spir-table td .icon-spirometry-second-bestresult {
    color: #bababa;
  }
  .spir-table .best-result {
    font-weight: bold;
  }
  .spir-table td:nth-child(n+2) {


  }
  .panels-block {
    margin-top: 15px;
    margin-left: 0px;
  }
  .panels-block .panel:first-child {
    margin-left:0;
  }
  .panels-block .panel {
    width: calc(25% - 11.25px);
    margin-left: 15px;
    box-shadow: 0px 0px 3px 0px rgba(0,43,75,0.2);
    margin-bottom: 15px;
  }
  .panel-heading {
    border-bottom: 1px solid #e5e5e5;
  }
  @include breakpoint(notebook) {
    .panels-block {
      margin-left: 0px;
    }
    .panels-block .panel {
      float: left;
      width: calc(50% - 7.5px);
    }
    .panels-block .panel:nth-child(odd) {
      margin-left:0;
    }
  }
  @include breakpoint(tablet) {
    .panels-block .panel {
      width: 100%;
      margin-left: 0;
    }
  }
  @include breakpoint(tablet) {
    .icon-spirometry-value {
      color: #b5d134;
    }
    .spir-table tr .thead:first-child {
      color: #727272;
      text-align: left;
      padding-top: 0;
      border-radius: 0px;
      padding-top: 5px;

    }
    .spir-table-overlay table {
      border-collapse: collapse;
    }
    .spir-table tr:last-child {

    }
    .spir-table tr:nth-child(even) {
      background-color: transparent;
    }
    .spir-table td {
      white-space: nowrap;
      border: 1px solid #e5e5e5;
      min-width: 80px;
    }
    .spir-table td:first-child {
      //position:absolute;
      background-color: #F1F1F2;
      width: 80px;
      border: 1px solid #e5e5e5;
    }
    .spir-table td:nth-child(2) {
      padding-left:10px;
    }
    .spir-table td:nth-child(odd) {
      background-color: #F1F1F2;
    }
    .spir-table .active {
      background-color: #F1F1F2;
    }

    .spir-table tr:nth-child(even) .active {
      background-color: #F1F1F2;
    }
    .spir-table tr .active-mobile:first-child {
      background-color: #498ec9 !important;
      color: #fff;
      border-right: #498ec9;
    }
    .spir-table tr .th-nhp {
      min-width: 80px;
    }
    .spir-table tr .th-nhp .icon {
      float: right;
    }
    .spir-table tr .th-nhp-1 {
      border-right: 4px solid $trial1Color;
    }
    .spir-table tr .th-nhp-2 {
      border-right: 4px solid $trial2Color;
    }
    .spir-table tr .th-nhp-3 {
      border-right: 4px solid $trial3Color;
    }
    .spir-table tr .th-nhp-4 {
      border-right: 4px solid $trial4Color;
    }
    .spir-table tr .th-nhp-5 {
      border-right: 4px solid $trial5Color;
    }
    .spir-table tr .th-nhp-6 {
      border-right: 4px solid $trial6Color;
    }
    .spir-table tr .th-nhp-7 {
      border-right: 4px solid $trial7Color;
    }
    .spir-table tr .th-nhp-8 {
      border-right: 4px solid $trial8Color;
    }
  }


  /* modal Spir table  */
  /*.spir-md-wrapper {
      color: #727272;
      background: #f1f2f3;
      position: absolute;
      border-radius: 3px;
      margin: -1px auto 0;
      display: inline-block;
      vertical-align: middle;
      width: 33%;
      text-align: left;
      top: 220px;
      right: 95px;
  }
  .spir-md-content {
      overflow-y: scroll;
      overflow-x: hidden;
      max-height: 80vh;
      width: 100%;
      min-height: 273px;
      background-color: #fff;
  }
  .spir-md-overlay {
      z-index: -1;
      background-color: transparent;
  }
  .spir-md-effect {
      transition: all 0.5s;
  }
  .spir-md-show.spir-md-effect {
      top: 0;
  }
  #sprirometry-details-modal {
      z-index: 0;
  }*/

  /*	.spir-md-show {
          visibility: visible;
      }
      .spir-md-show ~ .spir-md-overlay {
          opacity: 1;
          visibility: visible;
      }*/

  .tab-pane-spirometry {
    position: relative;
    overflow-x: hidden;
  }
  .tab-content{
    clear: both;
  }
  .slide-hide-desktop,.mobile-chart-slide{
    display: none;
    max-width: 100%;
  }

  //special styles for swiper
  @include breakpoint(tablet) {
    .swiper-slide, .swiper-wrapper{
      padding: 0;
      margin: 0;
    }
    .swiper-slide{
      width: 100% !important;
    }
    .swiper-pagination-clickable .swiper-pagination-bullet{
      margin: 0px 2px;
    }
    .swiper-pagination{
      position: relative !important;
    }
    .mobile-chart-slide{
      display: block;
      padding-top: 35px;
    }
  }


  .chart-num-mob{
    display:none;
    width: 100%;
    text-align: center;
  }

  @include breakpoint(tablet) {
    .spirometry-details-charts {
      overflow: hidden !important;
      text-align: center;
    }
    .spirometry-details-charts .mini-chart {
      width: 11% !important;
      margin: 10px auto !important;
    }
    .chart-num-mob{
      display: block;
    }
    .mini-chart canvas {
      display: none;
    }
    .spirometry-info {
      display: none;
    }
    .close-slide-btn{
      position: absolute;
      top: 10px;
      left: 10px;
    }
  }

  //detailed charts for desktop

  @include breakpoint(tablet){
    .chart-details-show-first,.chart-details-show-second{
      display:none!important;
    }

    .nav-tabs>li {
	    float: none;
	}
	.dropdown .btn-primary:active:focus,
	.dropdown.open>.dropdown-toggle.btn-primary:active:focus,
	.dropdown.open>.dropdown-toggle.btn-primary:focus:active:focus,
	.dropdown.open>.dropdown-toggle.btn-primary:hover:active:focus{
		display: inline;
	}
  }
  .chart-details-show-first,.chart-details-show-second{
    display:none;
    position: relative;
    margin-top: 50px;
  }

  .close-chart-btn{
    position: absolute;
    top: -40px;
    left: -10px;
  }

  .btn-btn{
    position: absolute;
    top: 0;
    left: 0;
  }
