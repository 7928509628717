ert-tooltip {
  $shadow-color: #727272;
  display: inherit;
  position: relative;

  .ert-interactive-tooltip {
    display: inline-block;
    bottom: 100%;
    z-index: 99999;
    position: absolute;
    padding: 5px;
    color: #727272;
    border-radius: 5px;
    margin: 5px 5px 12px 5px;
    min-width: 100px;
    box-shadow: 0 0 4px $shadow-color;
    background-color: white;
    font-stretch: condensed;

    .arrow {
      width: 70px;
      height: 15px;
      overflow: hidden;
      position: absolute;
      left: 50%;
      margin-left: -35px;
      bottom: -15px;
    }

    .arrow:after {
      content: "";
      position: absolute;
      left: 20px;
      top: -20px;
      width: 25px;
      height: 25px;
      box-shadow: 0px 0px 4px $shadow-color;
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}
