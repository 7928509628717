
.interactive-tooltip-wrap {
  position: relative;
}

.interactive-tooltip {

  bottom: 50px;
  width: inherit;
  z-index:99999;

  opacity: 0;
  display: inline-block;
  position: absolute;
  padding: 5px;
  color: #555;
  border-radius: 5px;
  margin-top: 20px;
  text-align: center;
  font-stretch: condensed;
  text-decoration: none;
  box-shadow: 0 0 7px black;

  .es-button {
    border-radius: initial;
    width: 100%;
    margin-bottom: 5px;
  }

  .arrow {
    width: 70px;
    height: 16px;
    overflow: hidden;
    position: absolute;
    left: 50%;
    margin-left: -35px;
    bottom: -16px;
  }
  .arrow:after {
    content: "";
    position: absolute;
    left: 20px;
    top: -20px;
    width: 25px;
    height: 25px;
    box-shadow: 6px 5px 9px -9px black,
    5px 6px 9px -9px black;
    transform: rotate(45deg);
  }
  &.active {
    opacity: 1;
    margin-top: 5px;
    transition: all 0.2s ease;
  }
  &.out {
    opacity: 0;
    margin-top: -20px;
  }
}

.interactive-tooltip, .arrow:after {
  background: white;
  border: 1px solid white;
}

