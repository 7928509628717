/*
 * ertGrid scss
 * */

@import '../../styles/configuration';

$calendar-cell-color-text-muted: #bcbdbc;

ert-grid {

	display: block;

	.ert-grid-visit-rule-passed {
		color: es-color('green','eukalyptus');
	}

	.ert-grid-visit-rule-failed {
		color: es-color('red','dark-red');
	}

	.ert-grid-row-action-icon-blue {
		color: $es-anchor-color;
	}

	.es-actionbar.row-action-cell > a.-es-grid-row-action-disabled,
	ul > li > a.ert-grid-action-subitem-disabled {
		[ng-click],
		[data-ng-click],
		[x-ng-click] {
			cursor: not-allowed;
		}
		cursor: not-allowed;
		color: es-color('mono','light-grey');
	}

	.ert-grid-cell-not-clickable {
		[ng-click],
		[data-ng-click],
		[x-ng-click] {
			cursor: default;
		}
		cursor: default;
	}

	.ert-grid-row-not-clickable {
		[ng-click],
		[data-ng-click],
		[x-ng-click] {
			cursor: default;
		}
		cursor: default;
		& .es-actionbar {
			[ng-click],
			[data-ng-click],
			[x-ng-click] {
				cursor: pointer;
			}
		}
	}
	.es-actionbar > div {
		display: inline-block;
	}

	table {
		.alternate-row-action-column {
			text-align: right;
		}
		.es-actionbar {
			margin-right: 20px;
			&.alternate-row-actions {
				margin-right: 0px;
				@extend %es-link-list;
				nav > ul > li > a.-es-muted {
					color: $calendar-cell-color-text-muted;
					cursor: default;
				}


			}
		}
	}

}
